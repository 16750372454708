<template>
  <div>
    <Toolbar class="mb-4">
      <template #left>
        <Button
          icon="pi pi-download"
          class="p-button-help"
          @click="exportCSV($event)"
          style="margin-right: 10px"
        />
        <Button icon="pi pi-print" @click="printPage()" label="Print" />
      </template>

      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger ml-2"
          @click="$router.go(-1)"
        />
      </template>
    </Toolbar>
    <div id="print">
      <DataTable
        ref="dt"
        :value="list"
        class="p-datatable-gridlines p-datatable-sm"
        showGridlines
      >
        <template #header>
          <div class="table-header">
            <!-- Massages Aufführen -->

            <div style="float: right">
              <!-- <Button
              icon="pi pi-download"
              class="p-button-help"
              @click="exportCSV($event)"
            /> -->
            </div>
          </div>
        </template>
        <template #empty>Keine Daten</template>
        <template #loading>Daten werden geladen, bitte warten... </template>

        <ColumnGroup type="header">
          <Row>
            <Column :rowspan="2" header="Tage" />
            <Column :rowspan="2" header="Preise" />

            <Column :rowspan="2" header="Hotel Gaste" />

            <Column :rowspan="2" header="Bar" />

            <Column :rowspan="2" header="Kartenzahlung" />
            <Column :rowspan="2" header="Gutschein" />
            <Column :rowspan="2" header="10er Block" />
            <Column :rowspan="2" header="online" />

            <Column :rowspan="2" header="Produkte" />
          </Row>
          <Row>
            <!-- <Column header="Preise" />
            <Column header="ZN" /> -->
            <!-- <Column header="EC" />
            <Column header="Kreditkarte" /> -->
          </Row>
        </ColumnGroup>

        <Column filterMatchMode="contains" field="name">
          <template #body="slotProps">
            {{ equ(slotProps.data.start) }}
          </template>
        </Column>
        <Column filterMatchMode="contains" field="Preise">
          <template #body="slotProps">
            {{
              slotProps.data.list
                .map((el) => el.Preise)
                .reduce((a, b) => Number(a) + Number(b), 0)
            }}
          </template>
        </Column>

        <Column filterMatchMode="contains" field="HotelPreise">
          <template #body="slotProps">
            {{
              slotProps.data.list
                .map((el) => el.HotelPreise)
                .reduce((a, b) => Number(a) + Number(b), 0)
            }}
          </template>
        </Column>

        <!-- <Column filterMatchMode="contains" field="HotelZN">
          <template #body="slotProps">
            {{
              slotProps.data.list
                .map((el) => el.HotelZN)
                .reduce((a, b) => Number(a) + Number(b), 0)
            }}
          </template>
        </Column> -->

        <Column filterMatchMode="contains" field="Bar">
          <template #body="slotProps">
            {{
              slotProps.data.list
                .map((el) => el.Bar)
                .reduce((a, b) => Number(a) + Number(b), 0)
            }}
          </template>
        </Column>
        <Column filterMatchMode="contains" field="Kartenzahlung">
          <template #body="slotProps">
            {{
              slotProps.data.list
                .map((el) => el.Kartenzahlung)
                .reduce((a, b) => Number(a) + Number(b), 0)
            }}
          </template>
        </Column>
        <Column filterMatchMode="contains" field="GutsheinBetrag">
          <template #body="slotProps">
            {{
              slotProps.data.list
                .map((el) => el.GutsheinBetrag)
                .reduce((a, b) => Number(a) + Number(b), 0)
            }}
          </template>
        </Column>
        <Column filterMatchMode="contains" field="BlockBetrag">
          <template #body="slotProps">
            {{
              slotProps.data.list
                .map((el) => el.BlockBetrag)
                .reduce((a, b) => Number(a) + Number(b), 0)
            }}
          </template>
        </Column>
        <Column filterMatchMode="contains" field="online">
          <template #body="slotProps">
            {{
              slotProps.data.list
                .map((el) => el.online)
                .reduce((a, b) => Number(a) + Number(b), 0)
            }}
          </template>
        </Column>
        <Column filterMatchMode="contains" field="Produkte">
          <template #body="slotProps">
            {{
              slotProps.data.list
                .map((el) => el.Produkte)
                .reduce((a, b) => Number(a) + Number(b), 0)
            }}
          </template>
        </Column>

        <!-- <Column filterMatchMode="contains" field="Zeit">
        <template #body="slotProps">
          
          {{slotProps.data.list.map((el) => el.Zeit).reduce((a, b) => Number(a) + Number(b), 0)}}
        </template>
      </Column> -->

        <ColumnGroup type="footer">
          <Row>
            <Column footer=" " :footerStyle="{ 'text-align': 'center' }" />
            <Column
              :footer="
                oldList
                  .map((el) => el.Preise)
                  .reduce((a, b) => Number(a) + Number(b), 0)
              "
              :footerStyle="{ 'text-align': 'center' }"
            />

            <Column
              :footer="
                oldList
                  .map((el) => el.HotelPreise)
                  .reduce((a, b) => Number(a) + Number(b), 0)
              "
              :footerStyle="{ 'text-align': 'center' }"
            />

            <!-- <Column :footer="''" :footerStyle="{ 'text-align': 'center' }" /> -->

            <Column
              :footer="
                oldList
                  .map((el) => el.Bar)
                  .reduce((a, b) => Number(a) + Number(b), 0)
              "
              :footerStyle="{ 'text-align': 'center' }"
            />
            <Column
              :footer="
                oldList
                  .map((el) => el.Kartenzahlung)
                  .reduce((a, b) => Number(a) + Number(b), 0)
              "
              :footerStyle="{ 'text-align': 'center' }"
            />
            <Column
              :footer="
                oldList
                  .map((el) => el.GutsheinBetrag)
                  .reduce((a, b) => Number(a) + Number(b), 0)
              "
              :footerStyle="{ 'text-align': 'center' }"
            />
            <Column
              :footer="
                oldList
                  .map((el) => el.BlockBetrag)
                  .reduce((a, b) => Number(a) + Number(b), 0)
              "
              :footerStyle="{ 'text-align': 'center' }"
            />
            <Column
              :footer="
                oldList
                  .map((el) => el.online)
                  .reduce((a, b) => Number(a) + Number(b), 0)
              "
              :footerStyle="{ 'text-align': 'center' }"
            />
            <Column
              :footer="
                oldList
                  .map((el) => el.Produkte)
                  .reduce((a, b) => Number(a) + Number(b), 0)
              "
              :footerStyle="{ 'text-align': 'center' }"
            />
            <!-- <Column
            :footer="oldList.map((el) => el.Zeit).reduce((a, b) => Number(a) + Number(b), 0)"
            :footerStyle="{ 'text-align': 'center' }"
          /> -->
          </Row>
        </ColumnGroup>
      </DataTable>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      oldList: [],
      loading: true,

      HotelPreise: true,
      HotelZN: true,
      Bar: true,
      kartekarte: true,
      Gutshein: true,
      karteVisa: true,
      kurs: true,
      online: true,
    };
  },
  methods: {
    printPage() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("print").innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=1024,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(
        this.$htmlPrint2(
          stylesHtml,
          prtHtml,
          `${this.$route.params.from} / ${this.$route.params.to}`
        )
      );
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    equ(value) {
      const duration = this.$moment(String(value));

      const d = duration.format("YYYY-MM-DD");
      return d;
    },
   
    getData() {
      this.$http.post(`appointments/myappointments?isR=0&date=${this.$route.params.from}`,{}).then(
        (response) => {
          this.loading = false;

          this.oldList = response.data.data;

          const list = [];

          for (const item of response.data.data) {
            const index = list.findIndex(
              (el) => this.equ(el.start) == this.equ(item.start)
            );
            if (index >= 0) {
              list[index].list.push(item);
            } else {
              list.push({
                start: item.start,
                list: [item],
              });
            }
          }
          this.list = list;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );
    },

    update(val, id, col) {
      console.log(val);
      console.log(id);
      console.log(col);
      let obj = {};
      obj[col] = val;
      this.$http.post(`reportsbyday/${id}`, obj).then(
        (response) => {
          this.$toast.add({
            severity: "success",
            summary: "Done Successfully",
            detail: "Updated Successfully",
            life: 3000,
          });
          // this.getData();
        },
        (err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style>
.xxx-checkbox .p-checkbox {
  margin-bottom: 22px;
  margin-right: 5px;
}
.fc .fc-view-harness {
  /* height: 856.296px !important; */
}

.fc .fc-scrollgrid-section table {
  width: 100% !important;
  /* height: 825px !important; */
}

.fc-scrollgrid-section-body .fc-scrollgrid-section table {
  width: 100% !important;
  /* height: 856.296px !important; */
}
</style>
